export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de',
  messages: {
    de: {
      menu: 'Menü',
      'open-menu': 'Menü öffnen',
      'close-menu': 'Menü schließen',
      error: {
        default: 'Etwas ist schiefgelaufen',
        'not-found': 'Seite konnte nicht gefunden werden',
      },
      'learn-more': 'Mehr erfahren',
      contact: 'Kontakt',
      focus: 'Fokusbereiche',
      caseStudies: 'Referenzen',
      imprint: 'Impressum',
      privacy: 'Datenschutz',
      departmentsCtaHeadline:
        'Wir können Projekte praktisch umsetzen, zuerst steht jedoch Idee, Konzept, Strategie.',
      previous: 'vorherige',
      next: 'nächste',
      discussProject: 'Projekt besprechen',
      with: 'mit',
      'older-messages': 'Ältere Nachrichten laden',
      sorry: 'Entschuldigung',
      noJobPosting:
        'Bei ATM suchen wir nicht aktiv nach Profilen und haben auch keine freiberuflichen Jobs anzubieten.',
      weSpecialize:
        'Wir freuen uns über Freelancer, die Lust auf zeitgemäße Webprojekte, modernen Tech-Stack und die Zusammenarbeit mit erfahrenen Freiberuflern haben. Check unsere Fokusbereiche um mehr zu unseren Studios zu erfahren und starte ein Projekt mit uns.',
      planMeeting: 'Gespräch vereinbaren',
      and: 'und',
      andMore: 'und mehr',
      launcherTitle: 'Chat mit uns',
    },
    en: {
      menu: 'Menu',
      'open-menu': 'Open menu',
      'close-menu': 'Close menu',
      error: {
        default: 'Something went wrong',
        'not-found': 'Page not found',
      },
      'learn-more': 'Learn more',
      contact: 'Contact',
      focus: 'Focus',
      caseStudies: 'Case studies',
      imprint: 'Imprint',
      privacy: 'Privacy',
      departmentsCtaHeadline:
        'We can implement projects practically, but first comes the idea, concept, strategy.',
      previous: 'previous',
      next: 'next',
      discussProject: 'Discuss Project',
      with: 'with',
      'older-messages': 'Load older messages',
      sorry: 'Sorry',
      noJobPosting:
        'ATM we are not actively looking for profiles, nor have any freelance gigs to offer.',
      weSpecialize:
        'We specialize in a diverse range of focus areas designed to meet your unique needs. Click on the menu to explore more about our services, expertise, and projects. At Welance, we are always on the lookout for passionate and talented collaborators who share our vision. Join us today and be part of a dynamic team that drives change and innovation.',
      planMeeting: 'Plan a Meeting',
      and: 'and',
      andMore: 'and more',
      launcherTitle: 'Chat with us',
    },
    it: {
      menu: 'Menu',
      'open-menu': 'Apri menu',
      'close-menu': 'Chiudi menu',
      error: {
        default: 'Qualcosa è andato storto',
        'not-found': 'Pagina non trovata',
      },
      'learn-more': 'Scopri di più',
      contact: 'Contatti',
      focus: 'Focus',
      caseStudies: 'Referenze',
      imprint: 'Imprint',
      privacy: 'Privacy',
      departmentsCtaHeadline:
        "Possiamo implementare i progetti praticamente, ma prima viene l'idea, il concetto, la strategia.",
      previous: 'precedente',
      next: 'successivo',
      discussProject: 'Discuti il ​​progetto',
      with: 'con',
      'older-messages': 'Carica i messaggi più vecchi',
      sorry: 'Scusa',
      noJobPosting:
        'ATM non stiamo cercando attivamente profili, né abbiamo lavori freelance da offrire.',
      weSpecialize:
        'Siamo specializzati in una vasta gamma di aree di interesse progettate per soddisfare diverse esigenze, sempre uniche. Clicca sul menu per saperne di più sui nostri servizi, competenze e progetti. In welance, siamo sempre alla ricerca di collaboratori appassionati e talentuosi che condividano la nostra visione. Unisciti a noi e fai parte di un team dinamico che promuove il cambiamento e l’innovazione.',
      planMeeting: 'Organizza un incontro',
      and: 'e',
      andMore: 'e altri',
      launcherTitle: 'Chatta con noi',
    },
  },
}))
